<template>
  <div class="auth-providers" :class="platform">
    <div class="email-registration">
      <StButton
        class="email-button"
        :size="isMobile ? 'l' : 'xl'"
        replace
        :to="{ query: { modal: 'register' } }"
      >
        <div class="email-registration-wrapper">
          <span>
            {{
              isMobile
                ? t('bonuses.takeBonus')
                : t('authorization.registration')
            }}
            <AnimatedGift class="email-present" />
          </span>
        </div>
      </StButton>
    </div>
    <div v-if="!isTelegramMiniApp" class="buttons-wrapper">
      <span class="buttons-text">{{ t('authorization.continueWith') }}</span>
      <div class="buttons">
        <StButton
          v-if="isGoogleEnabled"
          type="ghost"
          class="provider-button"
          :size="isMobile ? 'l' : 'm'"
          icon="c-google"
          data-t="google-button"
          :loading="currentLoadingProvider === 'google'"
          :label="isMobile ? t('registration.googleButtonShort') : ''"
          :is-left-icon="isMobile"
          @click="auth('google')"
        />
        <StButton
          class="provider-button"
          :size="isMobile ? 'l' : 'm'"
          type="ghost"
          icon="c-telegram"
          data-t="telegram-sign-in"
          :loading="currentLoadingProvider === 'telegram'"
          :is-left-icon="isMobile"
          :label="isMobile ? t('registration.telegramButton') : ''"
          @click="auth('telegram')"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTelegram } from '@st/telegram/composables/useTelegram'
import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import { useAuthProviders } from '../../composables/useAuthProviders'

const { platform, isMobile } = usePlatform()

const { t } = useI18n()

const { settings } = storeToRefs(useSettingsStore())
const isGoogleEnabled = computed(
  () => !!settings.value?.oidcGoogleRegistrationEnabled,
)

const { currentLoadingProvider, auth } = useAuthProviders()

const { isTelegramMiniApp } = useTelegram()
</script>

<style scoped>
.buttons-wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-075);
  justify-content: flex-end;

  min-width: 176px;
  height: 48px;
}

.buttons {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
}

.buttons-text {
  position: absolute;
  top: -6px;
  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
}

.email-registration {
  position: relative;
}

.email-button {
  min-width: 110px;
}

.email-present {
  position: absolute;
  top: 2px;
  right: -24px;

  width: 44px;
  height: 44px;
}

.auth-providers {
  display: flex;
  gap: var(--spacing-450);
  align-items: center;
  backdrop-filter: blur(1px);

  &.mobile {
    flex-direction: column;
    gap: var(--spacing-100);
    padding: 0 var(--spacing-200) var(--spacing-200);
    border-radius: var(--spacing-200);

    .buttons-wrapper {
      position: relative;

      gap: var(--spacing-100);
      align-items: center;

      width: 100%;
      height: auto;

      &::before {
        content: '';

        position: absolute;
        top: 7px;
        left: 0;

        width: 25%;
        height: 0.5px;

        background-color: var(--border-secondary);
      }

      &::after {
        content: '';

        position: absolute;
        top: 7px;
        right: 0;

        width: 25%;
        height: 0.5px;

        background-color: var(--border-secondary);
      }
    }

    .buttons-text {
      position: static;
      top: unset;
      margin: 0;
      font: var(--mobile-caption-1-regular);
    }

    .buttons {
      gap: var(--spacing-075);
      justify-content: center;
      width: 100%;
    }

    .provider-button {
      flex-grow: 1;
    }

    .email-registration-wrapper {
      position: relative;
    }

    .email-registration {
      width: 100%;
    }

    .email-present {
      top: -7px;
      right: -35px;
      width: 32px;
      height: 32px;
    }

    .email-button {
      width: 100%;
    }
  }
}
</style>
