import type { MobileTab } from '@st/ui/components/StMobileTabs/types'
import { useGamesCounter } from '../../composables/useGamesCounter'
import type { Game } from '../../types'

export interface UseMainCasinoReturn {
  allGamesCounter: Ref<number>
  activeTab: Ref<string>
  games: Ref<Game[]>
  showMoreLink: Ref<string>
  showMoreCounter: Ref<number>
  tabs: Ref<MobileTab[]>
  isLoading: ComputedRef<boolean>
}

const SLIDER_GAMES_NUMBER = 18

export function useMainCasino(): UseMainCasinoReturn {
  const casinoStore = useCasinoStore()
  const { mainPageCategories } = storeToRefs(casinoStore)
  const activeTab = ref<string>(mainPageCategories.value?.[0]?.code)
  const activeCategory = computed(() =>
    casinoStore.getCategoryByCode(activeTab.value),
  )

  watchEffect(() => {
    activeTab.value = mainPageCategories.value?.[0]?.code
  })

  const tabs = computed<MobileTab[]>(() =>
    mainPageCategories.value.map((category) => ({
      id: category.code,
      title: category.name || category.code,
      iconLink: category.iconLink,
    })),
  )

  const allGamesCounter = useGamesCounter()
  const { isMobile } = usePlatform()

  const { locale } = useI18n()

  const { data, status } = useStFetch('/casino/game/find', {
    method: 'post',
    server: false,
    body: computed(() => ({
      params: {
        language: locale.value as any,
        categoryCode: activeTab.value,
        ...(isMobile.value ? { forMobile: true } : { forDesktop: true }),
      },
      pagination: {
        page: 0,
        perPage: SLIDER_GAMES_NUMBER,
        orderBy: [
          {
            sortOrder: 'ASC' as const,
            fieldName: 'forbiddenByCountry' as const,
          },
          {
            sortOrder: 'DESC' as const,
            fieldName: 'weightInCategory' as const,
          },
        ],
      },
    })),
  })

  const showMoreCategoryCode = computed(
    () => activeCategory.value?.siblingCode ?? activeTab.value,
  )

  const games = computed<Game[]>(() => data.value?.data ?? [])
  const showMoreLink = computed(
    () => `/casino/categories/${showMoreCategoryCode.value}`,
  )
  const showMoreCounter = useGamesCounter(
    computed(() => ({
      categoryCode: showMoreCategoryCode.value,
    })),
  )

  const isLoading = computed(() => status.value !== 'success')

  return {
    allGamesCounter,
    tabs,
    activeTab,
    games,
    showMoreLink,
    showMoreCounter,
    isLoading,
  }
}
